import {
  genPageTheme,
  createThemeOptions,
  lightTheme,
  BackstageTheme,
  createThemeOverrides,
} from '@backstage/theme';
import { createTheme } from '@material-ui/core';

// colors from design
const blue = '#0000F8';
const black = '#171717';
const green = '#2EE778';
// const darkGrey = '#ACACAC';
const lightGrey = '#F3F3F3';
const white = '#FFFFFF';

// this is an empty black box with no variation
const pageHeaderTheme = genPageTheme({ colors: [black], shape: 'none' });

const lunarThemeOptions = createThemeOptions({
  defaultPageTheme: 'home',
  fontFamily: 'Poppins',
  palette: {
    ...lightTheme.palette,
    primary: {
      main: blue,
    },
    background: {
      default: white,
      paper: lightGrey,
    },
    navigation: {
      background: black,
      indicator: green,
      color: lightGrey,
      selectedColor: green,
    },
  },
  pageTheme: {
    home: pageHeaderTheme,
    documentation: pageHeaderTheme,
    tool: pageHeaderTheme,
    service: pageHeaderTheme,
    website: pageHeaderTheme,
    library: pageHeaderTheme,
    other: pageHeaderTheme,
    app: pageHeaderTheme,
    apis: pageHeaderTheme,
  },
});

const baseTheme = createTheme(lunarThemeOptions) as BackstageTheme;
const overrides = createThemeOverrides(baseTheme);

export const lunarTheme = {
  ...baseTheme,
  overrides,
};
