import {
  Content,
  ContentHeader,
  Header,
  Page,
  SupportButton,
} from '@backstage/core-components';
import { CatalogTable } from '@backstage/plugin-catalog';
import {
  EntityKindPicker,
  EntityListProvider,
} from '@backstage/plugin-catalog-react';
import React, { PropsWithChildren } from 'react';

const apiColumns = [
  CatalogTable.columns.createNameColumn(),
  CatalogTable.columns.createMetadataDescriptionColumn(),
];

const ApiExplorerLayout = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <Page themeId="apis">
      <Header title="APIs" subtitle="Lunar API Explorer" />
      {children}
    </Page>
  );
};

export const ApiExplorerPage = () => {
  return (
    <ApiExplorerLayout>
      <Content>
        <ContentHeader title="">
          <SupportButton>Any questions?</SupportButton>
        </ContentHeader>
        <EntityListProvider>
          <EntityKindPicker initialFilter="api" hidden />
          <CatalogTable columns={apiColumns} actions={[]} />
        </EntityListProvider>
      </Content>
    </ApiExplorerLayout>
  );
};
