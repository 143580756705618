import { InfoCard } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

type CardProps = {
  link: { title: string; link: string };
  title: string;
};

export const Card = ({
  link,
  title,
  children,
}: PropsWithChildren<CardProps>) => (
  <InfoCard deepLink={link}>
    <Typography variant="h4">{title}</Typography>
    <Typography variant="body1" gutterBottom>
      {children}
    </Typography>
  </InfoCard>
);
