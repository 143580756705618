import { Content, Header, Page } from '@backstage/core-components';
import {
  DefaultResultListItem,
  SearchBar,
  SearchResult,
} from '@backstage/plugin-search-react';
import { Grid, List } from '@material-ui/core';
import React from 'react';

export const searchPage = (
  <Page themeId="home">
    <Header title="Search" />
    <Content>
      <Grid container direction="row">
        <Grid item xs={12}>
          <SearchBar />
        </Grid>
        <Grid item xs={12}>
          <SearchResult>
            {({ results }) => (
              <List>
                {results.map(({ document, highlight, rank }) => (
                  <DefaultResultListItem
                    key={document.location}
                    result={document}
                    highlight={highlight}
                    rank={rank}
                  />
                ))}
              </List>
            )}
          </SearchResult>
        </Grid>
      </Grid>
    </Content>
  </Page>
);
