import { ApiEntity } from '@backstage/catalog-model';
import {
  ApiDefinitionWidget,
  defaultDefinitionWidgets,
} from '@backstage/plugin-api-docs';
import React from 'react';
import { OpenApiDefinitionWidget } from './OpenApiDefinitionWidget';

export const customDefinitionWidgets = () => {
  // load the default widgets
  const definitionWidgets = defaultDefinitionWidgets();

  return {
    getApiDefinitionWidget: (apiEntity: ApiEntity) => {
      // custom rendering for sql
      if (apiEntity.spec.type === 'openapi') {
        return {
          type: 'openapi',
          title: 'OpenAPI',
          rawLanguage: 'yaml',
          component: definition => (
            <OpenApiDefinitionWidget definition={definition} />
          ),
        } as ApiDefinitionWidget;
      }

      // fallback to the defaults
      return definitionWidgets.find(d => d.type === apiEntity.spec.type);
    },
  };
};
