/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 227 76.5"
    >
      <g>
        <path
          fill="#FFFFFF"
          d="M0,76.5l5.3-56.4l10.3-0.9l-4.4,46.2l18.2-1.7l-1,10.2L0,76.5z M52.9,72.2c-13.7,1.2-16.1-9.2-15.6-14.9l3.9-40.7l10.3-0.9 l-3.9,40.7c-0.5,5.3,4,5.7,6.2,5.5c2.1-0.2,6.8-1.4,7.3-6.7L65,14.5l10.3-0.9l-3.9,40.8C71,60.1,66.6,71,52.9,72.2z M105.5,66.9 l-11.3-31l-2.9,32.3L81,69.1l5.3-56.4l10.1-0.9l11.1,32l3-33.3l10.2-0.9l-5.3,56.4L105.5,66.9z M154.5,62.5l-2.1-12.6l-14.8,1.3 l-4.6,13.2l-10.7,0.9l20.2-57.7l12.7-1.1l9.8,55L154.5,62.5z M147.7,20.7L141,40.9l9.8-0.9L147.7,20.7z M197.3,58.6l-8.2-20.9 l-4.6,0.4l-2.1,21.8l-10.3,0.9l5.3-56.4l17.7-1.6c13.8-1.2,15.4,9.4,15,14.5l-0.2,2.5c-0.4,4-2.6,11.5-9.9,15.4l8.9,22.3 L197.3,58.6z M199.7,18.6c0.5-5.2-3.4-5.7-5.6-5.5l-7.2,0.6l-1.3,14.2l7.4-0.7c2.2-0.2,6.1-1.2,6.6-6.6L199.7,18.6z"
        ></path>
        <g>
          <path
            fill="#FFFFFF"
            d="M221.5,3.9L220.7,4l-0.1,1.6l0.8-0.1c0.2,0,0.7-0.1,0.7-0.7l0-0.2C222.2,4,221.7,3.9,221.5,3.9z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M221.5,0c-3.2,0-6.1,2.6-6.3,5.9c-0.2,3.2,2.2,5.9,5.5,5.9c3.2,0,6.1-2.6,6.3-5.9S224.7,0,221.5,0z M223.4,4.4l0,0.3 c0,0.5-0.3,1.3-1.1,1.8l1.1,2L222,8.6l-1-1.8l-0.5,0l-0.2,1.9L219,8.8l0.6-5.9l2.1-0.2C223.2,2.6,223.4,3.8,223.4,4.4z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default LogoFull;
