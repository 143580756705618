import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarItem,
  SidebarPage,
  useSidebarOpenState,
  useSidebarPinState,
} from '@backstage/core-components';
import { Link, makeStyles } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import ExtensionIcon from '@material-ui/icons/Extension';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help';
import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

const PinnedSidebar = ({ children }: PropsWithChildren<{}>) => {
  const { isPinned, toggleSidebarPinState } = useSidebarPinState();
  if (!isPinned) {
    toggleSidebarPinState();
  }
  return <Sidebar>{children}</Sidebar>;
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <PinnedSidebar>
      <SidebarLogo />
      <SidebarDivider />
      <SidebarItem icon={HomeIcon} to="home" text="Home" aria-label="Home" />
      <SidebarItem
        icon={LibraryBooks}
        to="docs"
        text="Docs"
        aria-label="Docs"
      />
      <SidebarItem
        icon={ExtensionIcon}
        to="api-docs"
        text="APIs"
        aria-label="APIs"
      />
      <SidebarItem
        icon={SearchIcon}
        to="search"
        text="Search"
        aria-label="Search"
      />
      <SidebarDivider />
      <SidebarItem
        icon={BarChartIcon}
        text="Up time"
        aria-label="Up time"
        onClick={() => window.open('https://status.lunar.app')}
      />
      <SidebarItem
        icon={HelpIcon}
        text="Support"
        aria-label="Support"
        onClick={() => window.open('https://github.com/lunarway/openbanking/issues')}
      />
    </PinnedSidebar>
    {children}
  </SidebarPage>
);
