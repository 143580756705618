import {
  Content,
  ContentHeader,
  Header,
  Page,
} from '@backstage/core-components';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Card } from './Card';
import { GetStartedStepper } from './GetStartedStepper';
import { SectionHeader } from './SectionHeader';

const RegisterNowCard = () => (
  <Card
    link={{
      title: 'Register now',
      link: '/docs/default/API/Registration',
    }}
    title="Register as Third Party Provider"
  >
    To use the Open Banking APIs you need to be registered as a Third Party
    Provider. <br />
    You can register right away in the sandbox environment and get going in less
    than a minute.
  </Card>
);

const JobsCard = () => (
  <Card
    link={{
      title: 'See open positions',
      link: 'https://jobs.lunar.app/',
    }}
    title="Be a part of something greater"
  >
    Lunar consists of tech enthusiasts, design nerds, marketing experts, - bird
    of a feather flock together - you get the idea
  </Card>
);

export const WelcomePage = () => {
  return (
    <Page themeId="home">
      <Header title="Open Banking Developer Portal" />
      <Content>
        <Grid container>
          <Grid item xs={12}>
            <ContentHeader title="Welcome to the Lunar Open Banking Developer Portal" />
            <Typography variant="body1" gutterBottom>
              This is the Lunar developer portal for the Open Banking APIs. Here
              you will find all the information you need to integrate as a Third
              Party Provider with Lunar and our users' data.
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <GetStartedStepper />
            </Grid>
            <Grid container item xs={12} md={6}>
              <SectionHeader title="Quick links" />
              <Grid item xs={12}>
                <RegisterNowCard />
              </Grid>
              <Grid item xs={12}>
                <JobsCard />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
