import {
  Content,
  ContentHeader,
  SupportButton,
} from '@backstage/core-components';
import { EntityListProvider } from '@backstage/plugin-catalog-react';
import {
  TechDocsPageWrapper,
  TechDocsPicker,
  EntityListDocsTable,
} from '@backstage/plugin-techdocs';
import React from 'react';

export const MinimalTechDocsHome = () => (
  <TechDocsPageWrapper>
    <Content>
      <ContentHeader title="">
        <SupportButton>Any questions?</SupportButton>
      </ContentHeader>
      <EntityListProvider>
        <TechDocsPicker />
        <EntityListDocsTable
          actions={[]}
          columns={[EntityListDocsTable.columns.createNameColumn()]}
        />
      </EntityListProvider>
    </Content>
  </TechDocsPageWrapper>
);
