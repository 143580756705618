import { configApiRef, createApiFactory } from '@backstage/core-plugin-api';
import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import { apiDocsConfigRef } from '@backstage/plugin-api-docs';
import { customDefinitionWidgets } from './components/OpenApiDefinitionWidget/customDefinitionWidgets';

export const apis = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: apiDocsConfigRef as any, // cast as tsc cannot inspect the underlying generic type
    deps: {},
    factory: () => customDefinitionWidgets(),
  }),
];
