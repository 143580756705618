import {
  InfoCard,
  SimpleStepper,
  SimpleStepperStep,
} from '@backstage/core-components';
import { Link } from '@material-ui/core';
import React from 'react';
import { SectionHeader } from './SectionHeader';

export const GetStartedStepper = () => (
  <>
    <SectionHeader title="Get started" />
    <InfoCard>
      <SimpleStepper>
        <SimpleStepperStep
          title="Register"
          actions={{
            showBack: false,
            showRestart: false,
          }}
        >
          <>
            You need to register as a Third Party Provider to consume the Open
            Banking APIs
          </>
        </SimpleStepperStep>
        <SimpleStepperStep
          title="Request access to user's data"
          actions={{
            showBack: true,
            showRestart: false,
          }}
        >
          <>
            With your TPP credentials you can request access to any Lunar user's
            data. They can accept your request in the Lunar app and grant you
            the required permissions.
          </>
        </SimpleStepperStep>
        <SimpleStepperStep
          title="Build beautiful solutions"
          actions={{
            showBack: true,
            showNext: false,
            showRestart: false,
          }}
        >
          <>
            Learn more on how to register in the{' '}
            <Link href="docs/default/API/registration">
              Registration documentation
            </Link>
          </>
        </SimpleStepperStep>
      </SimpleStepper>
    </InfoCard>
  </>
);
